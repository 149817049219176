<template>
<div class="CandidatureItem">
  <div class="gavc-card-row">
    <figure class="gavc-card-row__img">
      <img class="gavc-img-cover" :src="imageUrl" alt="image de l'annonce" style="max-width: 300px;">
    </figure>
    <div class="gavc-card-row__content gavc-margin-left">
      <div class="gavc-card-row__content--infos">
        <p class="gavc-title-offer" v-if="candidature.offre_cession.annonce">
          {{candidature.offre_cession.entreprise_cedee.name}} - {{candidature.offre_cession.annonce.title}}
        </p>
        <p class="gavc-title-offer" v-else>
          {{candidature.offre_cession.entreprise_cedee.name}}
        </p>
        <p class="gavc-title-price" v-if="candidature.offre_cession.annonce">
          Prix : {{formatThousandSeparatorNumber(parseFloat(candidature.offre_cession.annonce.price).toFixed(0))}} €
        </p>
        <p class="gavc-title-price" v-else-if="candidature.offre_cession.price">
          Prix : {{formatThousandSeparatorNumber(parseFloat(candidature.offre_cession.price).toFixed(0))}} €
        </p>
        <div class="gavc-card-offer__content--header__icon-text">
          <i class="gavc-icon gavc-icon-pin"></i>{{candidature.offre_cession.entreprise_cedee.city}} (siège social)
        </div>
      </div>
      <div v-if="candidature.offre_cession.annonce" class="gavc-card-row__content--desc gavc-text-small">
        <strong>Description:</strong>
        <span v-if="candidature.offre_cession.annonce.activite.length > 100">
          {{candidature.offre_cession.annonce.activite.substring(0, 100)}} ...<br>
        </span>
        <span v-else class="gavc-text-small gavc-margin-bottom">
          {{candidature.offre_cession.annonce.activite}}
        </span>
      </div>
      <div v-if="candidature.offre_cession.annonce" class="gavc-card-row__icon-text gavc-text-small">
        <i class="gavc-icon gavc-icon-silhouette"></i>
        {{candidature.offre_cession.nb_candidatures}}
        <span v-if="candidature.offre_cession.nb_candidatures <= 1">
          Candidat
        </span>
        <span v-else>
          Candidats
        </span>
      </div>
    </div>

    <div class="gavc-card-row__status">
      <i v-if="candidature.state === '1ONGOING'" class="gavc-icon gavc-icon-hourglass"></i>
      <i v-else-if="candidature.state === '2REFUSED'" class="gavc-icon gavc-icon-cross"></i>
      <i v-else-if="candidature.closing" class="gavc-icon gavc-icon-signature"></i>
      <i v-else-if="candidature.audit" class="gavc-icon gavc-icon-search-counter"></i>
      <i v-else-if="candidature.pourparler" class="gavc-icon gavc-icon-bubble-status"></i>
      <div v-if="candidature.state === '1ONGOING'" class="gavc-card-row__status--text gavc-text-center gavc-strong">
        Candidature
        <span class="gavc-strong text-uppercase">en cours de validation</span>
      </div>
      <div v-else-if="candidature.state === '2REFUSED'" class="gavc-card-row__status--text gavc-text-center gavc-strong">
        Candidature
        <span class="gavc-strong text-uppercase">refusée</span>
      </div>
      <div v-else-if="candidature.closing" class="gavc-card-row__status--text gavc-text-center gavc-strong">
        Closing
      </div>
      <div v-else-if="candidature.audit" class="gavc-card-row__status--text gavc-text-center gavc-strong">
        Audit
        <span class="gavc-strong text-uppercase">en cours</span>
      </div>
      <div v-else-if="candidature.pourparler" class="gavc-card-row__status--text gavc-text-center gavc-strong">
        Pourparlers
        <span class="gavc-strong text-uppercase">en cours</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import renderMixins from '@/mixins/renderMixins'
import {
  apiBaseUrl
} from '@/variables/localVariables'
export default {
  name: 'OffreCessionItem',
  props: {
    candidature: Object,
    withBaseApiUrl: {
      default: true,
      type: Boolean
    }
  },
  mixins: [
    renderMixins
  ],
  computed: {
    imageUrl() {
      if (this.candidature.offre_cession.annonce && this.candidature.offre_cession.annonce.images.length > 0) {
        if (this.withBaseApiUrl) {
          return apiBaseUrl + this.candidature.offre_cession.annonce.images[0].file
        }
        return this.candidature.offre_cession.annonce.images[0].file
      }
      return require('@/assets/images/default.jpg')
    }
  },
  methods: {
    accessCandidaturePage() {
      if (this.candidature.closing) {
        this.$router.push(`/closing/${this.candidature.closing.id}`)
      } else if (this.candidature.audit) {
        this.$router.push(`/audit/${this.candidature.audit.id}`)
      } else if (this.candidature.pourparler) {
        this.$router.push(`/pourparler/${this.candidature.pourparler.id}`)
      }
    }
  }
}
</script>
